@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.BannerContainer {
    background: #F6FBFE url(../imgs/BannerPattern.svg);
    padding: 18px;
}


.MuiContainer-maxWidthLg {
    max-width: 1220px !important;
}

h1 {
    color: #0B132B;
    font-size: 2.25rem;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-top: 0;
}

.TopTitle {
    color: #FF6200;
    font-size: 16px;
    line-height: 29px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

.devider {
    height: 1px;
    background: #E4E8EC;
}

.devider {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
}

.HeaderOneMainContainer .HeaderLogo img {
    height: 60px;
    margin-top: 5px;
    justify-content: left;
    margin-left: 4px;
}

.SocialIcons a img {
    margin-left: 4px;
    height: 40px;
    margin-top: 1rem;
    margin-right: 4px;
}


.Headertop {
    width: 100%;
    margin: 0 auto;
    border-bottom: 2px solid rgb(229, 223, 223);
    height: 70px;
    border-left: none;
    border-right: none;
}

.BtnGroup {
    display: flex;
    margin: 0 -8px;
}


.HeaderOneMainContainer {
    background: #fff;
    /* -webkit-box-shadow: 0 2px 6px 0 hsla(0, 0%, 68.6%, .51);
    box-shadow: 0 2px 6px 0 hsla(0, 0%, 68.6%, .51); */
    width: 100%;
    z-index: 99999;
    top: 0;
    height: 140px !important;
    left: 0;

}

.TopTitle {
    color: #FF6200;
    font-size: 16px;
    line-height: 29px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 85px;
}

.BtnGroup .MsInputButtonMainContainer {
    margin: 0 8px;
}

.CardEleven .CardElevenInfoContainer .CardElevenavatar {
    height: 326px !important;
}

.CardEleven:hover .CardElevenInfoContainer .CardElevenreadmore {
    color: #FF6200 !important;
    text-decoration: none !important;

}


.Photogallery .CarouselContainerItem {
    margin: 0 0px;
}

.Photogallery .rec-carousel-wrapper {
    margin-left: -14px;
    width: 103% !important;
}


.CarouselContainer .rec-arrow-left,
.CarouselContainer .rec-arrow-right {
    width: 24px;
    height: 34px;
    border-radius: 4px;
    min-width: 38px !important;
    background: #33B651;
}

.CarouselContainer .CarouselTitle {
    color: #0b132b !important;
    font-size: 34px !important;
    font-weight: 700 !important;
    text-transform: initial !important;
    margin-bottom: 20px !important;
    line-height: 46px !important;
    border-bottom: #e5e5e5 1px solid !important;
    padding-bottom: 23px !important;
}

.CarouselContainer .rec-arrow-left {
    right: 46px;
}

.OurPartner .UserAvatarFour .GuestCardOneSection .GuestCardOneImgCol {
    background: #fff;
    width: auto;
    height: 214px;
    border: 1px solid #e4e8ec;
    padding: 10px;
    margin: 0 auto;
}

.Blogs .CardEleven .CardElevenInfoContainer .CardElevenavatar {
    height: 196px !important;
}

.CTAOne a {
    background: #fff !important;
    padding: 20px !important;
    color: #FF6200 !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 0px !important;
}

.PageTitleOneMainContainer .PageTitleOneTextContainer .PageTitleOneMain {
    text-transform: initial;
}

.hrCta .CTAOne h2 {
    font-size: 34px;
    font-weight: 700;
    line-height: 43px;
    margin: 0;
    min-height: 162px;
}

.hrCta .CTAOne a {
    background: #fff;
    padding: 20px;
    color: #FF6200;
    width: auto;
    display: flex;
    justify-content: space-between;
    position: initial;
    margin-top: 42px;
}

.greenCard .CTAOne a {
    color: #33B651 !important;
}

.CTAOne {
    border-radius: 0;
}

.SectionCtn h1 {
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-top: 0;
}

.TextCetner {
    text-align: center;
}

.TextDard {
    color: #0B132B;
}

.TextWhite {
    color: #fff;
}

.TextWhite .UserAvatarOne .GuestCardOneSection .GuestCardOneTitle {
    color: #fff;
}

.TextWhite .UserAvatarOne .GuestCardOneSection .GuestCardOneDesignation {
    color: #fff;
}

.AboutUSContent h1 {
    text-align: center;
    margin-top: 32px;
}

.AboutUSContent p {
    max-width: 730px;
    margin: 0 auto;
    margin-bottom: 32px;
}

.MssionCard {
    background: #0B132B url(../imgs/RepeatPattern.svg);
    padding: 30px;
    text-align: center;
    color: #fff;

}

.VisionCard {
    background: #0B132B url(../imgs/RepeatPattern.svg);
    padding: 40px;
    text-align: center;
    color: #fff;

}

.AboutUSbanner {
    max-width: 1175px;
    margin: 0 auto;
    height: 468px;
    position: relative;
}

.AboutUSbanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.CardSixteen {
    background: #fff;
}


.InnerPageContentSection {
    max-width: 750px;
    margin: 0 auto;
}

.autherAndSocialDetails {
    display: flex;
    justify-content: space-between;
    border-bottom: #E4E8EC 1px solid;
    margin-bottom: 25px;
    margin-top: 25px;
    padding-bottom: 25px;
}

.autherAndSocialDetails .autherInfo {
    display: flex;
}

.autherAndSocialDetails .autherInfo .autherIcon {
    background: #EEF6F1;
    width: 44px;
    height: 44px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #56B149;
    margin-right: 12px;
}

.autherAndSocialDetails .autherInfo h2 {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails .autherInfo p {
    font-size: 14px;
    font-weight: 400;
    color: #676C7B;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails .SocialIcon a img {
    width: 44px;
    margin: 0 5px;
    cursor: pointer;
}

.ArticleContentsContainer p {
    font-size: 20px !important;
    color: #0B132B !important;
    line-height: 28px !important;
    font-weight: 400 !important;
    font-family: 'Poppins', sans-serif !important;
    margin-top: 25px !important;

}

.ArticleContentsContainer .Quots {
    border-left: #005AFF 2px solid;
    font-style: italic;
    padding-left: 20px;
}


.ArticleContentsContainer .articleImg {
    height: 400px;
    width: 100%;
    margin-top: 25px;
}

.ArticleContentsContainer .articleImg img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.ArticleContentsContainer h2 {
    font-size: 20px;
    color: #0B132B;
    font-weight: 700;
    line-height: 28px;
    margin-top: 25px;
}

.ArticleContentsContainer .youtubevideo {
    margin-top: 25px;
}

.ArticleContentsContainer .articleperagraph.ArtList {
    display: flex;
    align-items: baseline;
}

.ArticleContentsContainer .articleperagraph.ArtList svg {
    font-size: 12px;
    margin-right: 12px;
}

.articleTitle {
    font-size: 34px;
    font-weight: 700;
    color: #4D4D4D;
    font-family: 'Poppins', sans-serif !important;
    line-height: 40px;
}

.SocialIcon {
    display: flex;
    align-items: center;
}

.SocialIcon .CopyBtn {
    color: #444444 !important;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    background: #ffff;
    border: #ccc 1px solid;
    border-radius: 100px;
    padding: 10px 20px;
    margin-right: 10px;
}

.PageTitleOneMainContainer {
    position: relative;
    padding: 100px 25px;
    margin: 0 auto !important;
}

.PageTitleOneMainContainer .PageTitleOneTextContainer .PageTitleOneMain {
    font-size: 48px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
    margin: 0 auto;
}

.CardFifteen .CardFifteenInfoContainer .CardFifteenDetails h2 {
    font-size: 20px;
    margin: 0 !important;
    line-height: 24px !important;
}

/* Article Details CSS END */


.PageTitleOneMain {
    max-width: 1058px;
    margin: 0 auto;
}

.ParaSection {
    max-width: 730px;
    margin: 0 auto;
}

.ParaSection p {
    font-size: 20px;
    color: #0B132B;
    line-height: 30px;
    font-weight: 400;
}

.TopBannerCtn {
    margin: 0 auto;
    max-width: 570px;
    display: inherit;
}

.TopBannerCtn label {
    font-style: italic;
    color: #0B132B;
    font-size: 14px;
}

.ParaSection .TopBannerCtn img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.main-nav li a:hover {
    color: #FF6200 !important;
}

.main-nav .sub__menus li a:hover {
    color: #FF6200 !important;
}

.FooterThreeMainContainer .FooterThreeListinCol {
    margin-bottom: 16px !important;
    border-bottom: #676C7B 1px solid;
    padding-bottom: 8px !important;
}

.FooterThreeMainContainer .FooterThreeListinCol:last-child {
    border: none;
}

.AboutUSContent h1 {
    font-size: 48px !important;
}

.ContactUsThree .contactForm .ContactUsTitle {
    text-transform: initial !important;
    font-size: 34px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;

}

.MuiTabs-flexContainer {
    background-color: #f7f8fa;
    font-family: "Poppins", sans-serif;
    -webkit-justify-content: center;
    justify-content: center;
}

.terms-policy-tab button {
    /* color: #4d4d4d; */
    width: 100%;
}

.terms-policy-tab button.Mui-selected,
.terms-policy-tabs .Mui-selected {
    background-color: #fff;
    color: #33B651;
}

.MuiButtonBase-root.Mui-selected {
    border-bottom: 2px solid #33B651 !important;
}

.MuiTabs-indicator {
    background-color: #33B651 !important;
}

.TosContentContainer h1 {
    font-size: 34px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700 !important;
}

.TosContentContainer h2 {
    font-size: 20px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600 !important;
}

.TosContentContainer p {
    font-size: 14px !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
}


.PageTitleOneMainContainer .PageTitleOneTextContainer .PageTitleOneMain {
    text-transform: initial !important;
    margin: 0 auto;
}

.Blogs .CTAOne h2 {
    font-weight: 600 !important;
    min-height: 247px;
}

.rec-item-wrapper {
    display: block !important;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneDetails {
    padding: 20px !important;
    min-height: 120px !important;
    position: relative !important;
}

.VideoCardOne .VideoCardOneInfoContainer .VideoCardOneDesBox {
    padding: 20px 0 0;
    position: absolute !important;
    bottom: 17px !important;
}

.VideoCardOne:hover .VideoCardOneInfoContainer .VideoCardOnereadmore {
    color: rgb(255 98 0) !important;
}

.ContactInfoOne .ContactIfoListContainer .ContactIfoList {
    color: #0B132B;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    display: flex;
    margin-bottom: 10px;
}

.ContactInfoOne .ContactIfoListContainer .ContactIfoList span svg {
    color: #5e6474 !important;
    font-size: 21px;
    margin-right: 10px;
}

.FooterMainBoxBottomGrey {
    display: none !important;
}


.ModalFour .ModalFourMdlBody p {
    margin: 0 !important;
}

.fb-page {
    float: right;
}

.ImageGridFour {
    width: 1190px;
}

.ImageGridFour .GridCol {
    height: 220px;
    width: 226px;
}

.BtnGroup .MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    line-height: 0;
}

.CTAEight .CTAEightInner {
    width: 555px !important;
}

.VideoCardOne .VideoCardOnehover_over {
    opacity: .5 !important;
}

.VideoCardOne .videoIcon {
    opacity: 1 !important;
}

.CardFifteen .CardFifteenTopSec {
    text-transform: uppercase !important;
}

.CardFifteenTopSec span {
    background: none !important;
    color: #fff !important;
}

.CardFifteen .CardFifteenInfoContainer .CardFifteenreadmore {
    cursor: pointer;
}

.PageTitleOneMainContainer {
    background: #33B651 url(../imgs/RepeatPattern.svg);
    background-repeat: repeat;
    background-size: 40%;
}

.PageTitleOneMainContainer .backgroundPattern img {
    display: none;
}

.NewsImage {
    width: 100%;

}

.Newsdetails p {
    font-size: 16px;
}

.CardEleven .CardElevenInfoContainer .CardElevenDetails h2 {
    font-size: 22px !important;
    margin: 0 !important;
}
.CardEleven .CardElevenInfoContainer .CardElevenDetails h2 {
    align-items: center!important;
    display: flex!important;
    height: 18px!important;
    line-height: 26px!important;
    overflow: hidden!important;
    padding: 2.8rem 0!important;
}
.ModalFourBody {
    padding-bottom: 80px;
}

.HeaderLogo .Title {
    color: #14873c;
}

.NavBarEleven .main-nav ul.menuq2 {
    border-bottom: 3px solid #FF6200;
    top: 128px;
}

.BannerContainer {
    margin-top: 143px;
}

.InnerPageCtn {
    margin-top: 160px;
}


.CTAEight .CTAEightRightColBtn a img {
    height: 38px !important;
}

.CTAEight .CTAEightRightColBtn a {
    padding: 12px 20px !important;

}

.ModalFour .ModalFourMdlBody {
    height: 560px !important;
}

/*====== mobile view start======*/

@media (max-width: 1100px) {
    .ImageGridFour {
        width: auto;
    }
}


@media (max-width: 1100px) {


    .CTAEight .CTAEightInner {
        width: auto !important;
    }
}


@media (max-width: 990px) {


    .ImageGridFour .GridCol {
        max-height: 270px;
        width: 18%;
        max-width: initial;
        margin: 1%;
    }
}

@media (max-width:990px) {
    .ModalFour {
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        margin: 0 auto;
    }

    .ModalFourMdlBody {
        margin: 0;
        padding: 0;
        margin: 0 auto;
        width: 70%;

    }

    .ModalFourMdlBody h2 {
        font-size: 20px;
        display: flex;
        flex-direction: column;
    }

    .ModalFour .ModalFourimg {
        width: 100%;
    }
}

@media (max-width:990px) {
    h1 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 0.875rem;
    }

}

@media (max-width: 768px) {
    .VideoCardOne .VideoCardOneInfoContainer .VideoCardOneDetails {
        min-height: 109px !important;
    }

    .VideoCardOne .VideoCardOneInfoContainer .VideoCardOneDesBox {
        position: relative !important;

    }

    .BtnGroup {
        display: block;
        margin: 0 0px;
    }

    .MsInputButtonMainContainer .MsInputButton {
        font-size: 14px !important;
        text-transform: uppercase;
        padding: 0 23px;
        align-items: center;
        box-shadow: none !important;
        width: 100% !important;
        margin-bottom: 0px;
        text-align: center;
        justify-content: center;
    }



    .CarouselContainer .CarouselTitle {
        font-size: 26px !important;
        padding-top: 2px;
    }

    .CarouselContainer .rec-arrow-left,
    .CarouselContainer .rec-arrow-right {
        margin-right: 0 !important;
    }

    .autherAndSocialDetails {
        display: block;
    }

    .SocialIcon {
        margin-top: 20px;
    }

    .HeaderOneMainContainer .HeaderOneContainer .HeaderOnesiteLogo a {
        height: 41px;
    }

    .BannerContainer .MuiContainer-root {
        padding-left: 0;
        padding-right: 0;
    }


    .fb-page {
        float: none;
    }

    .FooterThreeMainContainer .PrivacySection {
        display: block;
    }

    .AlertThree .AlertThreeInner .AlertThreeRight .closeIcon {
        margin-left: 0;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .fb-pageContainer {
        max-width: fit-content !important;
        overflow-x: auto !important;
    }


    .FooterThreeMainContainer .FooterThreeRowTwo {
        text-align: center !important;
    }

    .FooterThreeMainContainer .FooterThreeListinCol:last-child {
        display: block !important;
    }

    .FooterThreeMainContainer .FooterThreeListinCol {
        display: block !important;
    }

    .FooterThreeMainContainer .FooterThreeSocial {
        justify-content: center !important;
    }

    .SectionMainContainer {
        padding: 25px 20px !important;
    }

    .AlertThree .AlertThreeInner {
        padding: 20px !important;
        display: block !important;

    }

    .AlertThree .AlertThreeInner .AlertThreeRight {
        display: flex !important;
        align-items: center !important;
        justify-content: end !important;
    }

    .MuiMobileStepper-root {
        padding: 0px !important;
    }

    .BtnGroup .MsInputButtonMainContainer {
        margin: 0px;
        margin-top: 19px !important;
    }

    .MsInputButtonMainContainer .MsInputButton {
        text-align: center !important;
        width: 100% !important;
        justify-content: center !important;
    }

    .FooterThreeMainContainer .PrivacySection {
        display: block !important;
    }

    .aboutusContents {
        width: 98%;
    }

    .ImageGridFour .GridCol {
        max-height: 270px;
        width: 48%;
        max-width: initial;
        margin: 1%;
    }

    .HeaderLogo .Title {
        display: none;
    }

    .HeaderOnesiteLogo img {
        height: 50px !important;
    }

    .InnerPageCtn {
        margin-top: 128px;
    }

    .BannerContainer {
        margin-top: 129px;
    }

    .CTAEight .CTAEightRightColBtn a img {
        height: 27px !important;
    }

    .CTAEight .CTAEightRightColBtn a {
        padding: 12px 20px !important;

    }

    .ModalThree .ModalThreeContainer {
        padding: 30px !important;
        width: 75% !important;
    }

    .ModalFour .ModalFourContainer {
        width: 100% !important;
    }

    .ModalFour {
        left: 50% !important;
        width: 92% !important;
    }

    .ModalFour .ModalFourMdlBody {
        height: 370px !important;
        width: auto !important;
        padding: 32px 11px !important;
    }

    .ModalFourFooter {
        padding: 16px 2% I !important;
        width: 96% !important;

    }

    .PageTitleOneMainContainer {
        padding: 40px 25px;
    }

}

/* --------------------------- */


.hideArros .rec-arrow {
    display: none;

}

.NavBarEleven .menu-item a:hover {
    color: #FF6200 !important;
}

.sub__menus li:hover {
    color: #FF6200 !important;
}

.NavBarEleven .menu-item .sub__menus li:hover a {
    color: #FF6200 !important;
    background: #f7f9fa;
}